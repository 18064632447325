
.nutrition-table {
    /*display: flex;*/
    /*flex-direction: column;*/
}

.nutrition-table .header {
    display: flex;
    background-color: #F6F4F0;
    padding: 15px;
    border-radius: 12px 12px 0 0;
}

.nutrition-table .header > div {
    padding: 7px 10px 7px 12px;
    border-radius: 17px;
    font-family: Inter;
    font-weight: 600;
    font-size: 12px;
}

.nutrition-table .header > div {
    background-color: #FFFFFF;
    color: #1F1F1F;
}

.nutrition-table .header > div:hover {
    cursor: pointer;
}

.nutrition-table .header .selected {
    background-color: #1F1F1F;
    color: #FFFFFF;
    margin-right: 10px;
}

.nutrition-table .body {
    display: flex;
    width: 100%;
    border-radius: 0 0 12px 12px;
    background-color: #FFFFFF;
    padding: 25px 30px;
    box-sizing: border-box;
}

.nutrition-table .nutrient-name {
    font-weight: 500;
    font-size: 14px;
    color: #1F1F1F;
}

.nutrition-table .column {
    flex-grow: 1;
}

.nutrition-table .column-space {
    background-image: linear-gradient(#d0cdc17a 33%, rgba(255,255,255,0) 0%);
    background-position: left;
    background-size: 2px 30px;
    background-repeat: repeat-y;
}

.nutrition-table .empty-content {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.nutrition-table .empty-content-header {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.nutrition-table .empty-content-header  {
    font-family: Inter;
    font-weight: 500;
    font-size: 10px;
    height: 45px;
}

.nutrition-table .empty-content-body {
    background-color: #F6F4F0;
    height: 100%;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.nutrition-table .empty-content-body > div {
    margin-top: 10px;
    font-weight: 400;
    font-size: 12px;
    text-align: center;
    color: #1F1F1F;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}