
.Modal {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    background-color: #82828282;
    z-index: 101;
    display: flex;
    align-items: center;
    justify-content: center;
}

.Modal > .content {
    min-height: 300px;
    min-width: 400px;
    max-height: 80vh;
    max-width: 80%;
    border-radius: 16px;
    position: relative;
    display: flex;
    flex-direction: column;
}

.Modal .header {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    color: #313131;
    padding: 25px 15px;
}

.Modal .body {
    flex-grow: 1;
    padding: 15px;
    overflow: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.Modal .body::-webkit-scrollbar {
    display: none;  /* Safari and Chrome */
}

.Modal .footer {
    height: 74px;
    display: flex;
    align-items: center;
    box-shadow: 0px -2px 22px rgb(0 0 0 / 11%);
    padding: 0 20px;
    position: sticky;
    width: 100%;
    bottom: 0;
    left: 0;
    box-sizing: border-box;
    background-color: #FFFFFF;
    border-radius: 0 0 12px 12px;
}

.Modal .footer .invalid {
    height: 74px;
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
    box-sizing: border-box;
    background-color: #ffffff8c;
    border-radius: 0 0 12px 12px;
}

.modal-close-btn {
    width: 26px;
    height: 26px;
    background-color: #313131;
    border-radius: 32px;
    position: absolute;
    top: 10px;
    right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
}

.modal-close-btn:hover {
    cursor: pointer;
    background-color: rgba(49, 49, 49, 0.8);
}