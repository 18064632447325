
.checkbox {
    position: relative;
}

.checkbox:not(.disabled) {
    cursor: pointer;
}

.checkbox input {
    /*position: absolute;*/
    /*opacity: 0;*/
    display: none;
}

.checkbox .mark {
    height: 18px;
    width: 18px;
    /*background-color: #DBDBDB;*/
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.checkbox.checked:not(.disabled) .mark {
    /*background-color: #FC634B;*/
}

.checkbox .mark:after {
    content: "";
    position: absolute;
    display: none;
}

.checkbox input:checked ~ .mark:after {
    display: block;
}

.checkbox input:not(:checked) ~ .mark > img {
    display: none;
}
