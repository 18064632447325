.nutrical-preview-table {
    border-radius: 12px;
    border: 4px solid #FFFFFF;
    padding: 12px;
    width: 400px;
    height: 250px;
    background-color: #f5f4f1;
}

.nutrical-preview-table table {
    width: 100%;
    height: 100%;
    table-layout: fixed;
    background-color: #FFFFFF;
    border-collapse: collapse;
    border-radius: 8px;
    border-style: hidden;
    border-spacing: 0px !important;
    box-shadow: 0 0 0 1px #666;
}

.nutrical-preview-table td {
    border: 1px solid #1F1F1F;
}

.nutrical-preview-table .rotate {
    width: 26px;
}

.nutrical-preview-table .rotate > div {
    transform: rotate(-90deg);
    white-space: nowrap;
    display: flex;
    justify-content: center;
    font-family: Inter;
    font-weight: 600;
    font-size: 12px;
    color: #1F1F1F;
}

.nutrical-preview-table .amount {
    width: 80px;
    text-align: center;
    font-family: Inter;
    font-weight: 600;
    font-size: 12px;
    color: #1F1F1F;
}

.nutrical-preview-table .name {
    font-family: Inter;
    font-weight: 500;
    font-size: 12px;
    color: #1F1F1F;
}

.nutrical-preview-table table tr:first-child td:first-child {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 8px;

}

.nutrical-preview-table table tr:first-child td:last-child {
    border-top-right-radius: 8px;
}

.nutrical-preview-table table tr:last-child td:last-child {
    border-bottom-right-radius: 8px;
}