.next-step-wrapper {
    height: 56px;
    width: 100%;
    flex-grow: 1;
    flex-shrink: 0;
}

.next-step {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #FFFFFF;
    box-shadow: 0 4px 52px 0 rgba(0, 0, 0, 0.13);
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.next-step > div {
    display: flex;
    align-items: center;
    justify-content: center;
}