.pdf-preview-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.pdf-preview-container .title {
    display: flex;
    justify-content: space-between;
    width: 100%;
    font-size: 28px;
    color: #1F1F1F;
    margin-bottom: 20px;
}

.textLayer {
    display: none;
}

.annotationLayer {
    display: none;
}

.pdf-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    flex-direction: column;
}

.pdf-preview-container .content {
    border-radius: 12px;
    overflow: hidden;
}