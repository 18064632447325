
.ingredient-search .db-select {
    display: flex;
}

.ingredient-search .db-select > div {
    background-color: #FFFFFF;
    padding: 7px 10px;
    border-radius: 17px;
    font-size: 12px;
    font-family: Inter;
    font-weight: 500;
    margin-right: 8px;
    color: #1F1F1F;
}

.ingredient-search .db-select .selected {
    background-color: #1F1F1F;
    color: #FFFFFF;
    font-weight: 600;
}

.ingredient-search .input-wrapper {
    margin-top: 14px;
    position: relative;

    background-color: #F6F4F0;
    border-radius: 12px;
    padding: 12px 15px;
}

.ingredient-search .input-wrapper img {
    position: absolute;
    left: 25px;
    top: 28px;
}


.ingredient-search .input-wrapper input {
    height: 48px;
    background-color: #FFFFFF;
    border-radius: 8px;
    outline: none;
    border: none;
    width: 100%;
    padding-left: 32px;
}


.ingredient-search .input-wrapper input:focus {
    border: 1px solid #31302A;
    box-shadow: 0 0 0 4px rgb(49 48 42 / 20%);
}

.ingredient-search .title {
    font-family: Inter;
    font-weight: 500;
    font-size: 20px;
    color: #1F1F1F;
}