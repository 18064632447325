
.nutrical-request {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.nutrical-request .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
}

