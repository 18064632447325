.ingredient-form {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    background-color: #82828282;
    z-index: 101;
    display: flex
}

.ingredient-form > div:nth-child(1) {
    flex-grow: 1;
}

.ingredient-form > div:nth-child(2) {
    flex-grow: 2;
    max-width: 900px;
    display: flex;
    align-items: center;
}

.ingredient-form > div:nth-child(3) {
    flex-grow: 1;
}

.ingredient-form .form {
    background-color: white;
    width: 100%;
    min-height: 500px;
    max-height: 80vh;
    border-radius: 16px;
    position: relative;
    display: flex;
    flex-direction: column;
}

.ingredient-form .form-body {
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
}
.ingredient-form .form-body::-webkit-scrollbar {
    display: none;  /* Safari and Chrome */
}

.ingredient-form .form-header {
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    color: #1F1F1F;
    padding: 25px;
}

.ingredient-form .form-body {
    padding: 25px;
    margin-bottom: 80px;
    overflow: auto;
}

.ingredient-form .form-footer {
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: end;
    box-shadow: 0px -2px 22px rgb(0 0 0 / 11%);
    padding: 0 20px;
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
    box-sizing: border-box;
    background-color: #FFFFFF;
    border-radius: 0 0 12px 12px;
}

.ingredient-form .invalid-form {
    height: 80px;
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
    box-sizing: border-box;
    background-color: #ffffff8c;
    border-radius: 0 0 12px 12px;
}

.ingredient-form .nutrition {
    display: flex;
}

.ingredient-form .nutrition > div {
    flex-grow: unset !important;
}

.ingredient-form .nutrition > div:first-child {
    margin-right: 50px;
}

.ingredient-form .table-type {
    display: flex;
}

.ingredient-form .table-type > div:first-child {
    margin-right: 10px;
}

.ingredient-form .table-type > div {
    font-family: Inter;
    font-weight: 500;
    font-size: 12px;
    color: #1F1F1F;
    padding: 6px 14px 8px;
    background: #ECE9E1;
    border-radius: 17px;
}

.ingredient-form .table-type > div:hover {
    cursor: pointer;
}

.ingredient-form .table-title {
    font-family: Inter;
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    color: #121212;
    margin-top: 30px;
    margin-bottom: 15px;
}

.ingredient-form .table-type .selected {
    color: #FFFFFF !important;
    background-color: #1F1F1F !important;
}

body :has(.ingredient-form) {
    height: 100vh;
    overflow-y: hidden;
}

.ingredient-form .ingredient-text {
    width: 100%;
    min-height: 60px;
    max-height: 200px;
    border-radius: 8px;
    background-color: #e0ded45c;
    padding: 10px;
}