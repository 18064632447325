.text-declaration {
    flex-grow: 1;
    border: 4px solid #FFFFFF;
    background-color: rgba(255, 255, 255, 0.5);
    padding: 20px;
    border-radius: 12px;
}

.text-declaration .label {
    font-family: Inter;
    font-weight: 500;
    font-size: 10px;
    color: #121212;
}

.text-declaration .pct {
    color: #EC8E00;
    font-weight: 600;
}